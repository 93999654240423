:root {
  // Base Scheme
  --base1: #010103; // Body
  --base2: #131315; // Header, Aside, Cards Dark
  --base3: #171718; // Cards Medium
  --base4: #222224; // Light, Inputs, Cards Light, Disabled, Tags
  --base5: #242426; // Light Hover
  --base6: #343436; // Light Active, Scrollbars, Tags
  --base7: #7c7c80; // Btn Disabled Text
  --base8: #888892; // Muted Icons
  --base9: #b2b2b7; // Muted Text
  --base9a: hsl(0, 0%, 100%); // Placeholder of custom selects
  --base10: #ffffff; // Text, Headers, Outlined Buttons
  --base11: white; // Text, Headers, Outlined Buttons (darkest)
  // Accents
  --colordanger: #ff265a; // Danger
  --colorwarning: #ffc700; // Warning
  --colorsuccess: #42dc82; // Success
  // Special
  --colortransparent: rgba(0, 0, 0, 0); // Transparent
  --colorinherit: inherit; // Reset
  --colordarktransparent: rgba(0, 0, 0, 0.6); // Dark Overlay
  --colorlighttransparent: rgba(255, 255, 255, 0.05); // Light Overlay
}
