@import "../assets/scss/SplashScreen.scss";
@import "../assets/scss/ScrollTop.scss";
@import "../assets/scss/Selecto.scss";
@import "../assets/scss/CheckMark.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--base2);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--base10);
}

.App-link {
  color: var(--base10);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hover:hover {
  cursor: pointer;
  text-decoration: underline;
}

.aside-menu .menu .menu-item .menu-link .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  fill: var(--base8);
}

.grey [fill]:not(.permanent):not(g) {
  fill: var(--base8);
}

.green-dot {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--colorsuccess);
}

/* VIS Timeline */

.vis-timeline {
  border: none;
}

.vis-timeline,
.vis-labelset .vis-label,
.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right,
.vis-panel.vis-bottom,
.vis-panel.vis-center,
.vis-panel.vis-top,
.vis-foreground .vis-group {
  border: none !important;
}

.vis-labelset .vis-label .vis-inner {
  color: var(--base6);
  font-weight: 600 !important;
  font-size: 1.075rem !important;
}

.vis-labelset .vis-label .vis-inner small {
  color: var(--base8) !important;
  font-weight: 500;
  font-size: 0.98rem;
  display: block !important;
}

.vis-item {
  border: none !important;
  background-color: var(--base10) !important;
}

.vis-item-before {
  background-color: var(--base10) !important;
}

.vis-item-after {
  background-color: var(--base9) !important;
}

/* ################### BEGIN GENERAL STYLE ################### */

body {
  background-color: var(--base1) !important;
}

.bg-light,
.btn-light,
.custom-form-control {
  background-color: var(--base4) !important;
  color: var(--base10) !important;
  border: none;
}

.bg-white,
.header,
.aside,
.background-dark,
.footer,
.bg-dark {
  background-color: var(--base2) !important;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-left: 120px;
  z-index: 10;
}

.badge-light {
  color: var(--base10) !important;
  background-color: var(--base4) !important;
}

.badge-light:hover {
  color: var(--base10) !important;
  background-color: var(--base6) !important;
}

.input-group-append {
  background-color: var(--base2) !important;
  color: var(--base10) !important;
  border: none;
}

.colormedium {
  color: var(--base10) !important;
}

.p-05-1 {
  padding: 0.5rem 1rem;
}

.custom-form-control-disabled {
  background-color: var(--base4) !important;
  color: var(--base7) !important;
  border: none;
}

.custom-form-control-user {
  background-color: var(--base6) !important;
  color: var(--base10) !important;
  border: none;
}

.custom-form-control-active {
  background-color: var(--base6) !important;
}

.custom-form-control-invalid {
  border: 1px solid;
  border-color: var(--colordanger) !important;
}

.custom-form-control-warning {
  border: 1px solid;
  border-color: var(--colorwarning) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.fs-6,
.fs-5,
.fs-4,
.fs-3,
.fs-2,
.fs-1,
.card-label,
.text-hover-primary,
.vis-inner {
  color: var(--base10) !important;
}

.card {
  border-radius: 5px !important;
  border: solid var(--base1) 1px;
}

.card .bg-light {
  border-radius: 5px !important;
  border: solid var(--colortransparent) 1px !important;
}

.card .bg-light:not(.no-hover):hover {
  background-color: var(--base6) !important;
  transition: 0.25s;
}

.card .bg-light2 {
  background-color: var(--base4) !important;
  border-radius: 5px !important;
  border: solid var(--colortransparent) 1px !important;
}

.card .bg-light2-hover:hover {
  background-color: var(--base5) !important;
  cursor: pointer;
  transition: 0.25s;
}

.btn.btn-light.active,
.btn.btn-light.show,
.btn.btn-light:active:not(.btn-active),
.btn.btn-light:focus:not(.btn-active),
.btn.btn-light:hover:not(.btn-active),
.show > .btn.btn-light {
  background-color: var(--base6) !important;
}

.btn.btn-light.disabled.active,
.btn.btn-light.disabled.show,
.btn.btn-light.disabled:active:not(.btn-active),
.btn.btn-light.disabled:focus:not(.btn-active),
.btn.btn-light.disabled:hover:not(.btn-active),
.show > .btn.btn-light.disabled {
  background-color: #232323 !important;
}

.text-muted,
.text-gray,
.menu-link,
input::placeholder {
  color: var(--base9) !important;
}

.list-no-bullets {
  list-style-type: none;
}

.timeline-content {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.timeline-item:not(.no-hover):hover {
  background-color: var(--base4);
  cursor: pointer;
  transition: 0.25s;
}

.scroll-y {
  overflow-y: scroll;
  scrollbar-color: var(--base6) !important;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--base6);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-thumb {
  border: 3px solid var(--base6);
}

.stat:hover {
  background-color: var(--base4);
  border-radius: 5px !important;
  cursor: pointer;
  transition: 0.25s;
}

.stat {
  cursor: pointer;
}

.tagify {
  padding-left: 10px;
  --tag-remove-bg: none !important;
  --tag-invalid-bg: var(--base7) !important;
  --input-color: var(--base10) !important;
  border-radius: 0.475rem !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif !important;
  line-height: 1.1rem !important;
  --placeholder-color: var(--base9) !important;
  --placeholder-colorfocus: var(--base9) !important;
}

.tagify .tagify__tag.selected {
  --tag-bg: var(--base5) !important;
  --tag-hover: var(--base6) !important;
}

/* ################### END GENERAL STYLE ################### */

/*
   * Loading Dots
   * Can we use pseudo elements here instead :after?
   */
.loading span {
  display: inline-block;
  vertical-align: middle;
  color: var(--base10);
  animation: loading 1s infinite alternate;
  position: absolute;
  left: 45%;
  top: 40%;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.time {
  padding: 0.55rem 2.25rem 0.55rem 1.25rem;
  font-size: 0.925rem;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat !important;
  background-position-y: 50% !important;
  background-position-x: 95% !important;
  background: transparent;
  border: 1px solid var(--base10);
  color: var(--base10);
  height: 100%;
}

.results tr {
  border-bottom-color: var(--base6) !important;
}

.nav-line-tabs .nav-item .nav-link {
  color: var(--base8);
}

.form-check.form-check-solid .form-check-input {
  background-color: var(--base6);
}

.form-check-input.disabled {
  opacity: 0.5
}

.no-hover table,
tbody,
thead,
tr,
td,
th {
  cursor: auto;
}

.table.table-row-dashed tr {
  border-bottom-style: solid;
  border-bottom-color: var(--base1);
}

.table:not(.no-hover).table-row-dashed tbody > tr:hover {
  background: var(--base4);
}

.table td:last-child,
.table th:last-child,
.table tr:last-child {
  padding-right: 15px !important;
}

.table td:first-child,
.table th:first-child,
.table tr:first-child {
  padding-left: 15px !important;
}

.react-kanban-column {
  z-index: 0 !important;
}

.react-trello-card {
  z-index: 99 !important;
}

.kanban-card {
  background-color: var(--base4);
  border-radius: 5px;
  margin: 5px 0px;
  padding: 7px;
  cursor: pointer;
}

.kanban-card:hover {
  background-color: var(--base6) !important;
}

.react-trello-board {
  background-color: var(--base1) !important;
}

.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: var(--colordarktransparent);
  display: flex;
}

.modal-content {
  box-shadow: 0px 0px 15px 5px var(--base1);
}

.nav-custom {
  padding: 0 10px !important;
}

.modal-content-scroll {
  max-height: 60vh;
  overflow-y: scroll;
}

.custom-input-group-text {
  background-color: var(--base4);
  color: var(--base10);
  border: 1px solid var(--base4);
}

.opacity-04 {
  opacity: 0.4 !important;
}

.fs-3rem {
  font-size: 3rem;
}

.fs-2-5rem {
  font-size: 2.5rem;
}

.fs-1-4rem {
  font-size: 1.4rem;
}

.float-right {
  float: right;
}

.country-icon {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  object-fit: cover;
}

.border-bottom-dark-gray {
  border-bottom: 2px solid var(--base4) !important;
}

.table-no-padding tr:first-child,
.table-no-padding td:first-child,
.table-no-padding th:first-child,
.table-no-padding th {
  padding-left: 0px !important;
}

.table-no-padding tr:last-child,
.table-no-padding td:last-child,
.table-no-padding th:last-child,
.table-no-padding th:last-child {
  padding-right: 0px !important;
}

.border-none {
  border: none !important;
}

.dropdown-toggle:not(.show-arrow)::after {
  display: none !important;
}

.note {
  padding: 1rem;
  background: var(--base6);
}

.modal-footer:not(.border-top) {
  border-top: none !important;
}

.disabled {
  cursor: not-allowed !important;
  pointer-events: initial !important;
}

.select-default.disabled > div {
  cursor: not-allowed !important;
  pointer-events: initial !important;
}

.select-default > div {
  border: none !important;
  border-radius: 0.475rem !important;
  font-size: 1.1rem !important;
  font-weight: 500 !important;
  line-height: 1.5 !important;
  padding-left: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.select-default-dark > div {
  background-color: var(--base2) !important;
}

.custom-link {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  display: inline-block;

  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    top: 1rem;
    left: 0;
    width: 0%;
    transition: width 0.3s ease;
  }

  &:hover {
    text-decoration: none !important;

    &:after {
      width: 100%;
      border-bottom: 1px solid var(--base10);
    }
  }
}

.custom-tagify-dropdown {
  background-color: var(--base4);

  & * {
    background-color: var(--base4) !important;
    transition: none !important;
    color: var(--base10) !important;
  }

  & *:hover {
    background-color: var(--base6) !important;
  }
}

.bg-custom-light-gray {
  background-color: var(--base4) !important;
}

.bg-custom-medium-gray {
  background-color: var(--base3) !important;
}

.custom-tooltip > .tooltip-inner {
  background-color: var(--base6) !important;
  max-width: 25vw !important;
}

.custom-tooltip > .tooltip-arrow::before {
  border-top-color: var(--base6) !important;
}

.custom-tooltip-danger > .tooltip-inner {
  background-color: var(--colordanger) !important;
  max-width: 25vw !important;
}

.custom-tooltip-danger > .tooltip-arrow::before {
  border-top-color: var(--colordanger) !important;
}

.custom-tooltip-warning > .tooltip-inner {
  background-color: var(--colorwarning) !important;
  max-width: 25vw !important;
}

.custom-tooltip-warning > .tooltip-arrow::before {
  border-top-color: var(--colorwarning) !important;
}

.custom-tooltip,
.custom-tooltip-large > .tooltip-inner {
  max-width: 70vw !important;
}

.custom-dropdown {
  background-color: var(--base6) !important;

  > .dropdown-item:hover {
    background-color: var(--base4);
  }
}

.select-wrapper {
  position: relative;
}

.select-wrapper::after {
  pointer-events: none;
  color: var(--base10);
  content: "╲╱";
  font-size: 0.4rem;
  top: 14px;
  right: 10px;
  position: absolute;
}

.custom-accordion-toggle {
  transition: transform 0.3s ease-out;
  transform: rotate(180deg);
}

.custom-accordion-toggle.show {
  transform: rotate(0deg);
}

.hide-arrows::-webkit-outer-spin-button,
.hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
.hide-arrows {
  -moz-appearance: textfield !important;
}

.invoiceFooter tr th {
  padding: 0.4rem !important;
}

.rdw-colorpicker-modal {
  height: auto !important;
  min-width: 180px;
  width: auto !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.rdw-colorpicker-modal-options {
  scroll: none;
  overflow: auto !important;
  background-color: var(--colortransparent) !important;
}

.rdw-colorpicker-modal-header {
  display: none !important;
}

.rdw-colorpicker-option {
  min-height: 0;
  box-shadow: none;
  margin: 0;
}

.rdw-dropdown-carettoopen,
.rdw-dropdown-carettoclose {
  display: none !important;
}

.rdw-option-wrapper,
.rdw-dropdown-wrapper {
  background-color: var(--base4) !important;
  border: none !important;
  height: 20px !important;
  width: 20px !important;
}

.rdw-option-wrapper img,
.rdw-dropdown-wrapper img {
  filter: invert(100) !important;
  opacity: 0.5;
}

.rdw-option-active,
.rdw-option-wrapper:hover,
.rdw-dropdown-active,
.rdw-dropdown-wrapper:hover {
  background: var(--base6) !important;
  box-shadow: none !important;
}

.rdw-fontsize-dropdown {
  width: 20px !important;
}

.public-DraftStyleDefault-block {
  margin: 0px !important;
}

.rdw-editor-main {
  padding-top: 5px !important;
}

.progress-btn {
  background: linear-gradient(
                  -120deg,
                  transparent 1em,
                  var(--colorsuccess) 1.05em,
                  var(--colorsuccess) 1.5em,
                  var(--colorsuccess) 2.05em
  ) top no-repeat,
  linear-gradient(300deg, transparent 1em, var(--colorsuccess) 1.05em, var(--colorsuccess) 1.5em, var(--colorsuccess) 2.05em) bottom no-repeat;
  background-size: 100% 50%;
}

.progress-background {
  background: var(--colorsuccess) !important;
}

.progress-background-canceled {
  background: var(--colordanger) !important;
}

.progress-background-requested {
  background: var(--colorwarning) !important;
}

.star-lit {
  color: var(--colorwarning) !important;
}

.star-hover:hover {
  color: var(--base10) !important;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.pointer {
  cursor: pointer !important;
}

tbody.table-row-small-padding > tr td {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

tbody.table-row-extra-small-padding > tr td {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.table tbody.table-row-extra-small-padding > tr th {
  border-bottom: 2px solid red !important;
}

.table tbody.table-row-extra-small-padding > tr td {
  border-bottom: 1px solid black !important;
}

.form-check.form-check-custom.form-check-xs .form-check-input {
  height: 1.2rem;
  width: 1.2rem;
}

.nav-button {
  background-color: var(--base4);
}

.nav-button.active {
  background-color: var(--base6);
}

.nav-button:hover {
  background-color: var(--base6);
}

img.image-gray {
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

.search-options {
  background: var(--base4);
  position: absolute;
  width: 100%;
  max-height: 75vh;
  overflow: scroll;
}

.search-options .search-option {
  color: var(--base10);
  padding: 3px;
}

.search-options .search-option:hover {
  background-color: var(--base5);
  padding: 3px;
}

.cursor-pointer-row > td {
  cursor: pointer;
}

.text-success-hover {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  display: inline-block;

  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    top: 1rem;
    left: 0;
    width: 0%;
    transition: width 0.3s ease;
  }

  &:hover {
    color: var(--colorsuccess) !important;
    text-decoration: none !important;

    &:after {
      width: 100%;
      border-bottom: 1px solid var(--colorsuccess) !important;
    }
  }
}

.text-danger-hover {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  display: inline-block;

  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    top: 1rem;
    left: 0;
    width: 0%;
    transition: width 0.3s ease;
  }

  &:hover {
    color: var(--colordanger) !important;
    text-decoration: none !important;

    &:after {
      width: 100%;
      border-bottom: 1px solid var(--colordanger) !important;
    }
  }
}

.text-warning-hover {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  display: inline-block;

  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    top: 1rem;
    left: 0;
    width: 0%;
    transition: width 0.3s ease;
  }

  &:hover {
    color: var(--colorwarning) !important;
    text-decoration: none !important;

    &:after {
      width: 100%;
      border-bottom: 1px solid var(--colorwarning) !important;
    }
  }
}

.remove-signature-button-relative {
  position: relative;
  left: -10px;
  top: -10px;
  background: var(--base10) !important;
  color: var(--base8) !important;
  transition: all 0.2s;
  vertical-align: top;
}

.remove-signature-button-relative.remove-signature-button-danger:hover {
  background: var(--colordanger) !important;
  color: var(--base10) !important;
  cursor: pointer;

  > i {
    color: var(--base10) !important;
  }
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.progress-bar-vertical {
  min-width: 10px;
  max-width: 20px;
  min-height: 100px;
  margin-right: 5px;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
  background-color: var(--base4);
  color: var(--colordanger) !important;
}

.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}

.fileContainer {
  box-shadow: none;
  background: none;
  padding: 0;
  margin: 0 auto;
}

.fileContainer .chooseFileButton {
  color: var(--base10);
  background: transparent;
  font-weight: 500;
  font-size: 0.925rem;
  line-height: 1.5;
  border: 1px solid var(--base10);
  border-radius: 0.475rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
  transition-property: color, background-color, border-color, box-shadow;
}

.fileContainer .chooseFileButton:hover {
  background: var(--base10);
  color: var(--base1);
  border-color: var(--base10);
}

.fileContainer .chooseFileButton.light {
  color: var(--base1);
  background: #eeeeee;
  font-weight: 500;
  font-size: 0.925rem;
  line-height: 1.5;
  border: 1px solid var(--base10);
  border-radius: 0.475rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
  transition-property: color, background-color, border-color, box-shadow;
}

.fileContainer .chooseFileButton.light:hover {
  background: #dddddd;
  color: var(--base1);
  border-color: var(--base10);
}




.reset-image-button-relative {
  position: relative;
  left: -10px;
  top: -10px;
  margin-right: -20px;
  margin-bottom: -20px;
  background: var(--base10) !important;
  color: var(--base8) !important;
  transition: all 0.2s;
  vertical-align: top;
}

.reset-image-button-relative.reset-image-button-danger:hover {
  background: var(--colordanger) !important;
  color: var(--base10) !important;
  cursor: pointer;

  > i {
    color: var(--base10) !important;
  }
}

.dropzone {
  height: 10vh;
  bordercolor: "darkgray";
  borderbottomleftradius: "5rem";
  borderbottomrightradius: "5rem";
}

.dropzone p {
  margin-top: 2px;
  margin-bottom: 0;
}

.dropzone button {
  width: 5vw;
  height: 5vh;
}

.previewContainer {
  display: flex;
  flexdirection: "row";
  flexwrap: "wrap";
  margintop: 16;
}

.previewContainer p {
  color: var(--base10);
  borderradius: 2;
  border: 1px solid var(--base10);
  marginbottom: 8;
  marginright: 8;
  width: 100px;
  height: 100px;
  padding: 4px;
  boxsizing: "border-box";
}

.previewInner {
  display: flex;
  minwidth: 0;
  overflow: hidden;
}

.previewInner img {
  display: block;
  width: 15vw;
  height: 15vh;
}

.input-no-style {
  border: none;
  display: inline;
  font-family: inherit;
  font-size: inherit;
  padding: 0;
  width: auto;
  color: var(--base10);
}

.input-no-style:focus-visible {
  outline: none;
}

.fc {
  --fc-bg-event-opacity: 1 !important;
}

.fc-multimonth-title {
  color: var(--base10);
}

.fc-col-header-cell {
  display: none;
}

.fc-multimonth-daygrid {
  border: 0 solid var(--fc-border-color) !important;
  height: 80%;
}

.fc-multimonth-daygrid-table {
  height: 100% !important;
}

.fc-multimonth {
  border: none !important;
}

.fc-day {
  border: none !important;
}

.valid-week {
  background-color: var(--base3) !important;
}

.valid-week:hover {
  background-color: var(--base4) !important;
  transition: 0.25s;
}

.fc-daygrid-week-number {
  background-color: var(--colortransparent) !important;
  color: var(--base10);
  height: 100%;
}

.fc-button {
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}

.fc-toolbar-chunk {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.fc-event {
  background-color: var(--colorsuccess) !important;
}

.invisible-link {
  cursor: pointer;
  text-decoration: none;
  position: relative;
  display: inline-block;

  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    top: 1rem;
    left: 0;
    width: 0;
    transition: width 0.3s ease;
  }

  &:hover {
    text-decoration: none !important;

    &:after {
      width: 0;
      border-bottom: 0;
    }
  }
}

.fa-bell-regular:hover {
  color: var(--colorwarning);
}

::-webkit-calendar-picker-indicator {
  filter: invert(1) brightness(0.5);
}

#react-select-3-listbox {
  z-index: 99;
}

.btn-disabled {
  background-color: var(--base4) !important;
  color: var(--base7) !important;
}

.btn.btn-light.btn-disabled:hover {
  background-color: var(--base4) !important;
  z-index: 0;
  cursor: not-allowed;
}

.fs-2rem {
  font-size: 2rem !important;
}

.apexcharts-tooltip {
  background: var(--base1) !important;
}

.tab-disabled {
  opacity: 0.6;
  pointer-events: none;
}

.card .bg-light2.border-bottom-0 {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.border-bottom-0 {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.border-top-0 {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.search-options {
  max-height: 0px;
  transition: max-height 250ms ease-in-out;
}

.search-options.show {
  max-height: 50vh;
}

.search-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--colortransparent);
  transition: background-color 250ms ease-in-out;
}

.search-backdrop.show {
  background: var(--colordarktransparent);
}

.z-11 {
  z-index: 11 !important;
}

.badge-gray {
  background-color: var(--base6);
}

.search-option-link {
  display: block;
  width: 100%;
  font-weight: 600;
  text-decoration: none !important;
}

.aside-menu .menu .menu-item .menu-link:hover:not(.disabled):not(.active),
.aside-menu .menu .menu-item.hover > .menu-link:not(.disabled):not(.active) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: var(--colorlight-transparent) !important;
}

.aside-menu .menu .menu-item .profile:hover:not(.disabled):not(.active),
.aside-menu .menu .menu-item.hover > .profile:not(.disabled):not(.active) {
  background-color: var(--colorinherit) !important;
}

.indeterminate-progress-bar {
  background-color: var(--base6);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101; // Header got 100
  height: 3px;
  overflow: hidden;
}

.indeterminate-progress-bar__progress {
  background-color: var(--base8);
  position: absolute;
  bottom: 0;
  top: 0;
  width: 40%;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-name: indeterminate-progress-bar;
}

.indeterminate-progress-bar.hidden {
  transition: background-color 2s;
  background-color: var(--base4);
}

@keyframes indeterminate-progress-bar {
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
}

.hover-text {
  opacity: 0;
  transition: opacity 0.4s;
}

.hover-show-text:hover .hover-text {
  opacity: 1;
  background-color: var(--base4);
}

// Use through variant of progress bar
.bg-white2 {
  background-color: var(--base10);
}

.icon-light:hover {
  color: var(--base10) !important;
}

.element-show-hover:not(:hover) {
  opacity: 0 !important;
}

.tagify__tag:hover {
  padding: 0.2rem 0.4rem !important;
  margin: 0.2rem 0.4rem 0.2rem 0 !important;
}

.badge-medium-gray {
  background-color: var(--base4);
}

.badge-light-gray {
  background-color: var(--base6);
}

.w-60 {
  width: 60%;
}

.background-hover-light:hover {
  background-color: var(--base4) !important;
  border: none;
  border-radius: 5px !important;
}

.bg-color10 {
  background-color: var(--base4);
}

.text-white {
  color: var(--base10) !important;
}

.btn-outline-light {
  color: var(--base10);
  border-color: var(--base10);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: var(--base10);
  background-color: var(--colortransparent);
}

.btn.btn-success {
  color: var(--base1);
  border-color: var(--colorsuccess);
  background-color: var(--colorsuccess);
}

.text-success {
  color: var(--colorsuccess) !important;
}

.text-danger {
  color: var(--colordanger) !important;
}

.text-warning {
  color: var(--colorwarning) !important;
}

i {
  color: var(--base8);
}

.text-active-white.active {
  color: var(--base1);
}

.colorbase-1 {
  color: var(--base1);
}

.colorbase-2 {
  color: var(--base2);
}

.colorbase-3 {
  color: var(--base3);
}

.colorbase-4 {
  color: var(--base4);
}

.colorbase-5 {
  color: var(--base5);
}

.colorbase-6 {
  color: var(--base6);
}

.colorbase-7 {
  color: var(--base7);
}

.colorbase-8 {
  color: var(--base8);
}

.colorbase-9 {
  color: var(--base9);
}

.colorbase-10 {
  color: var(--base10);
}

.bg-colorbase-1 {
  background-color: var(--base1);
}

.bg-colorbase-2 {
  background-color: var(--base2);
}

.bg-colorbase-3 {
  background-color: var(--base3);
}

.bg-colorbase-4 {
  background-color: var(--base4);
}

.bg-colorbase-5 {
  background-color: var(--base5);
}

.bg-colorbase-6 {
  background-color: var(--base6);
}

.bg-colorbase-7 {
  background-color: var(--base7);
}

.bg-colorbase-8 {
  background-color: var(--base8);
}

.bg-colorbase-9 {
  background-color: var(--base9);
}

.bg-colorbase-10 {
  background-color: var(--base10);
}

.colordanger {
  color: var(--colordanger);
}

.colorwarning {
  color: var(--colorwarning);
}

.colorsuccess {
  color: var(--colorsuccess);
}

.bg-colordanger {
  background-color: var(--colordanger);
}

.bg-colorwarning {
  background-color: var(--colorwarning);
}

.bg-colorsuccess {
  background-color: var(--colorsuccess);
}

.nav-group.order-nav-group,
.nav-group.order-nav-group .btn {
  background-color: #191919 !important;
}

.nav-group.order-nav-group .btn a {
  padding: 3px !important;
}

ul.delivery-timeline {
  list-style-type: none;
  position: relative;
}

ul.delivery-timeline:before {
  content: ' ';
  background: #343434;
  display: inline-block;
  position: absolute;
  left: 11px;
  width: 2px;
  height: 100%;
  z-index: 9;
}

ul.delivery-timeline > li {
  margin: 30px 10px;
  padding-left: 0px;
}

ul.delivery-timeline > li.open:before {
  content: ' ';
  background: #232323;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #343434;
  left: 0px;
  width: 24px;
  height: 24px;
  z-index: 10;
}

ul.delivery-timeline > li.done:before {
  content: ' ';
  background: #50cc8a;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #343434 !important;
  left: 0px;
  width: 24px;
  height: 24px;
  z-index: 10;
}

ul.delivery-timeline > li.canceled:before {
  content: ' ';
  background: #cc5050;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #343434 !important;
  left: 0px;
  width: 24px;
  height: 24px;
  z-index: 10;
}

@keyframes fade {
  0%, 100% { background: #50cc8a; }
  50% { background: #343434 }
}

ul.delivery-timeline > li.current:before {
  content: ' ';
  background: #50cc8a;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #343434 !important;
  left: 0px;
  width: 24px;
  height: 24px;
  z-index: 10;
  animation: fade 2s infinite;
}

.apexcharts-tooltip {
  box-shadow: none !important;
}

.tab-disabled {
  opacity: 0.6;
  pointer-events: none;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.h100-80px {
  height: calc(100% - 80px);
}

.symbol.symbol-78px > img {
  width: 78px;
  height: 78px;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.blinking-dot {
  height: 5px;
  width: 5px;
  background-color: #50cd89;
  border-radius: 50%;
  animation: blink 2s infinite;
  display: inline
}

.delivery-nav {
  display: flex;
  justify-content: space-between;
  padding: 0;
  list-style-type: none;
}

.delivery-nav-item {
  flex-grow: 1;
  margin: 0;
  padding: 0;
}

.bg-light.active {
  background-color: #343434 !important;
}

.delivery-nav-item a.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.options-muted option {
  opacity: .2
}

.muted__single-value {
  color: #b2b4bd !important;
}

.blur-anonymous {
  filter: blur(6px);
  -webkit-filter: blur(6px);
  user-select: none;
  cursor: pointer;
}

.tile:hover {
  background-color: #3f3f3f;
}



@media (min-width: 1400px) {
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

}

@media (min-width: 2300px){
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
}

.order-rentability-header {
  background-color: #2a2a2a;
}

.order-rentability-selection-column {
  background-color: #212121;
}

.order-rentability-total-column {
  background-color: #2a2a2a;
}

.organic-logo {
  height: 24px;
}

.organic-code {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("~/public/assets/media/logo.png") !important;
}

.card-amount-destinations {
  background: #3f3f3f;
}

.card-container {
  background-color: #232323;
  border: none;
}


.scrollbar-hidden {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 2;
}

html {
  scroll-padding-top: 105px; // Header + Price Ticker height
}

.fallback-logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("~/public/assets/media/logo.png");
  height: 75px;
  display: block;
}