.splash-screen-relative {
  position: relative;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  animation: rotate 1s linear infinite;
  margin-left: calc(100vw - 100%);
  width: 50px;
  height: 50px;
}

.spinner .path, .splash-spinner .path {
  stroke: #e5e5e5;
  stroke-linecap: round;
  animation: dash 1s ease-in-out infinite;
}

.splash-spinner {
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}