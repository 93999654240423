
.scrolltop {
  display: none;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  cursor: pointer;
  z-index: 100;
  background-color: #525252 !important;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.075);
  opacity: 0;
  transition: color 0.2s ease, background-color 0.2s ease;
  border-radius: 0.475rem;
}

.scrolltop .svg-icon svg {
  height: 24px;
  width: 24px;
}

.scrolltop .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fff;
}

.scrolltop .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

.scrolltop > i {
  font-size: 1.3rem;
  color: #fff;
}

.scrolltop:hover > i {
  color: #fff;
}

.scrolltop:hover .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #fff;
}

.scrolltop:hover .svg-icon svg:hover [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
}

[data-kt-scrolltop="on"] .scrolltop {
  opacity: 0.3;
  animation: animation-scrolltop 0.4s ease-out 1;
  display: flex;
}

[data-kt-scrolltop="on"] .scrolltop:hover {
  transition: color 0.2s ease, background-color 0.2s ease;
  opacity: 1;
}

@media (max-width: 991.98px) {
  .scrolltop {
    bottom: 30px;
    right: 15px;
    width: 30px;
    height: 30px;
  }
}

@keyframes animation-scrolltop {
  from {
    margin-bottom: -15px;
  }
  to {
    margin-bottom: 0;
  }
}