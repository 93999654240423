body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ticker {
  background-color: #1e1e2d !important;
}

#kt_header {
  background-color: #1e1e2d !important;
}

#search {
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border: 0px;
}

.aside-menu .menu .menu-item .menu-link.active {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.form-control.form-control-flush {
  color: rgb(194, 199, 216);
}

.nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link.active,
.nav-line-tabs.nav-line-tabs-2x .nav-item .nav-link:hover:not(.disabled),
.nav-line-tabs.nav-line-tabs-2x .nav-item.show .nav-link {
  border-bottom: none;
}

.wrapper {
  padding-left: 0 !important;
}

.content {
  min-height: 87vh;
}

.card {
  border: 1px solid #eff2f5;
  box-shadow: none;
  background-color: rgba(245, 248, 250, 0.35);
}

.aside {
  top: 104px;
  width: 120px !important;
}
.aside-menu {
  width: 120px !important;
}
.aside-menu .menu .menu-item .menu-link {
  flex-direction: column;
}

.menu-item {
  cursor: pointer;
}

.aside-menu .menu .menu-item .menu-link .menu-icon i {
  color: rgb(129, 133, 148);
  margin: 10px 0;
}

/* marquee */
.marquee {
  max-width: 100vw; /* iOS braucht das */
  white-space: nowrap;
  overflow: hidden;
  /* hier evtl. noch font-size, color usw. */
}

.marquee .header-animation {
  display: inline-block;
  animation: marquee 10s linear infinite;
}

.marquee .header-animation-repeat {
  display: inline-block;
  animation: marquee 10s linear infinite;
  animation-delay: 5s;
}

/* Make it move */
@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

tr:hover {
  cursor: pointer;
  /*   background-color: rgba(245, 248, 250, 0.35); */
  transition: all 0.2s;
}

.form-check.form-check-solid .form-check-input:checked {
  background-color: #555555;
}

.page-item.active .page-link {
  background-color: #444444 !important;
  color: white;
}

.page-item .page-link:hover {
  background-color: #ebebeb !important;
  color: #2f2f2f;
}

.page-item .page-link:focus {
  background-color: #333333 !important;
  color: white;
}

.btn.btn-active-light-primary:hover {
  background-color: red;
}

.dashboard-container {
  padding-left: 150px !important;
}

/* ################################################################# darkmode */
/* 
.card {
  border-radius: 17px;
  background: #f0f0f0;
  box-shadow: 6px 6px 12px #e4e4e4, -6px -6px 12px #ffffff;
}

.aside-menu {
  background-color: white;
}

.page {
  background: #f0f0f0;
} */

/* ################################################################# darkmode */

/* .card,
.page,
.ticker {
  background-color: black;
}

.header,
.aside {
  background-color: rgb(28, 28, 30) !important;
}

a,
span,
p,
.text-gray-800,
.fw-bolder,
h2,
.form-control,
.form-select-solid,
.form-control-solid,
.text-dark,
.btn {
  color: white !important;
}

.text-gray-600,
.text-gray-400,
.text-muted {
  color: rgb(156, 154, 161) !important;
}

.badge-light-primary,
.btn-light-primary,
.btn-bg-light,
.badge-light,
.form-control,
.form-select,
.btn-light,
.bg-light {
  background-color: rgb(57, 56, 60) !important;
}

.separator,
.border,
.form-control-solid,
.form-select-solid,
.btn-outline-secondary {
  border-bottom-color: rgb(92, 91, 97) !important;
  border-color: rgb(92, 91, 97) !important;
}

.badge-light-warning {
  background-color: rgb(177, 106, 0);
}

.badge-light-success {
  background-color: rgb(0, 119, 79);
} */
