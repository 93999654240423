.selecto-area {
    border-style: dotted;
    border-width: 2px;
    border-color: #121212;
}

.selecto-area:hover {
    border-color: #555555 !important;
}

.selecto-selectable {
    border-style: solid;
    border-color: rgba(245, 248, 250, 0.35) !important;
    border-width: 2px !important;
}

.selecto-area .selecto-to-be-selected {
    border-style: dashed;
    border-width: 2px;
    border-color: #0BB783 !important;
}

.selecto-area .selecto-selected {
    border-style: solid;
    border-width: 2px;
    border-color: #0BB783 !important;
}

.selecto-area .selecto-to-be-removed {
    border-style: dashed;
    border-width: 2px;
    border-color: #ff265a !important;
}
